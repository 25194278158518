import parse from 'html-react-parser';
import PhotoSwiper from './PhotoSwiper';
import axios from 'axios';
import { useState, useEffect } from 'react';
import { APIURL } from '..';
import { useContext } from 'react';
import { AppContext } from '../App';

const ZeptejSe = () => {
    const [data, setData] = useState();
    const {language, tab, zeptejse_title, zeptejse_perex, obchod_text, diplomacie_text, turismus_text, obchod_link,diplomacie_link, turismus_link, pravo_text, pravo_link, business_text, business_link, diplomatic_text, diplomatic_link } = useContext(AppContext);

    const getStudents = async () => {
        await axios.post(APIURL+'/collection/items', {type: 'students', orderAsc: 'ASC', limit: 100, page: 1,language: 'cs'}).then(response =>{
            setData(response.data.body.items);
        })
    }
    const filter = name => data.filter(i=>i.program.includes(name)).filter(i=>(language==='cz'?i.degree?.toLowerCase()===(tab==='bc'?'bc':'ing')||!i.degree:true))
    
    useEffect(()=>{
        getStudents();
    }, [tab])
    if (data) return (
        <section className="py-top px-2vw">
            <h2 className='text-center tablet:text-left'>{zeptejse_title}</h2>
            <p className='text-center tablet:text-left py-4'>{zeptejse_perex}</p>
            {language==='cz'?<>
            <ZeptejseSekce heading='Mezinárodní obchod' text={obchod_text} url={obchod_link} data={filter('obchod')} />
            <ZeptejseSekce heading='Mezinárodní studia a diplomacie' text={diplomacie_text} url={diplomacie_link} data={filter('diplomacie')} reverse />
            <ZeptejseSekce heading='Turismus a hospitality management' text={turismus_text} url={turismus_link} data={filter('hospitality')} />
            <ZeptejseSekce heading='Podnikání a právo' text={pravo_text} url={pravo_link} data={filter('právo')} reverse />
                </>:<>
            <ZeptejseSekce heading='International Business' text={business_text} url={business_link} data={data.filter(i=>i.program.includes('business'))} />
            <ZeptejseSekce heading='International and Diplomatic Studies' text={diplomatic_text} url={diplomatic_link} data={data.filter(i=>i.program.includes('diplomatic'))} reverse />
            </>}
        </section>
    )
}

const ZeptejseSekce = ({heading, text, url, data, reverse}) => {
    const {language} = useContext(AppContext);
    if (data.length>0) return (
    <div className={"py-4 flex tablet:flex-col justify-between items-center "+(reverse?'flex-row-reverse':'')}>
        <div className="w-2/5 tablet:w-full z-[2]">
            <h3>{heading}</h3>
            {text&&<div className='parsedDiv'>{parse(text)}</div>}
            {url&&<a href={url} className='button' target="_blank">{language==='cz'?"Zjistit více":'more info'}</a>}
        </div>
        <div className="w-3/5 tablet:w-full py-4">
            <PhotoSwiper data={data} />
        </div>
    </div>
)}

export default ZeptejSe