import logo from '../assets/svg/logo.svg'
import { showCookieConsent } from './UI/Cookies';
import parse from 'html-react-parser';
import { useContext, useEffect, useState } from 'react';
import { AppContext } from '../App';
import axios from 'axios';
import { APIURL } from '..';

const Footer = ({address}) => {
    const showConsent = () => {
        showCookieConsent();
    }
    const [soc, setSoc] = useState();
    const {language} = useContext(AppContext);

    const getData = async () => {
        await axios.post(APIURL+'/collection/items', {type: 'socials', orderAsc: 'ASC', limit: 100, page: 1,language: 'cs'}).then(response =>{
          setSoc(response.data.body.items);
        })
      }
    
      useEffect(()=>{
        getData();
      }, [])
    return (
        <footer>
            <div className="footer">
                <div className="footer__left">
                    <img src={logo} alt="" />
                </div>
                <div className='footer__right'>
                    <div className='parsedDiv'>
                        {parse(address)}
                    </div>
                    <div>
                        <div className='icons pb-4'>
                            {soc?.map(s=><a key={s.id} href={s.url} target="_blank">{parse(s.icon)}</a>)}
                        </div>
                        <span onClick={showConsent}>Nastavení soukromí</span>
                    </div>
                </div>
            </div>
            {/* <div className="copyright">Copyright © {new Date().getFullYear()} Vysoká škola ekonomická v Praze</div> */}
        </footer>
    );
}
  
export default Footer 