import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectCoverflow } from 'swiper/modules';
import { APIURL, STORAGEURL } from "..";
import FsLightbox from "fslightbox-react";
import ReactPlayer from 'react-player';

import 'swiper/css';

const GallerySwiper = () => {
    const [data, setData] = useState();
    const [srcs, setSrcs] = useState(null);
    const [slide, setSlide] = useState(null);
    const [lightbox, setLightbox] = useState(false);
    let isDesktop = window.innerWidth>1000;

    const getData = async () => {
        await axios.post(APIURL+'/collection/items', {type: 'gallery', orderAsc: 'ASC', limit: 100, page: 1,language: 'cs'}).then(response =>{
            let data = response.data.body.items;
            let max = isDesktop?6:3;
            if (data.length<max) {
                setData(data.concat(data));
            } else {
                setData(data);
            }
            setSrcs(data.map(i=>(STORAGEURL+i.src)));
        })
    }
    useEffect(()=>{
        getData();
    }, [])
    if (data) return (
        <section className="py-2top px-vw">
            <Swiper
                modules={[Autoplay,EffectCoverflow]}
                autoplay={true}
                loop={true}
                effect="coverflow"
                coverflowEffect={{depth: 200, rotate:0,slideShadows:false}}
                slidesPerView={isDesktop?3:1}
                spaceBetween={50}
                grabCursor={true}
                className="gallerySwiper"
            >
                {data.map((file,i)=>(
                    <SwiperSlide key={file.id+i} className="!h-[unset]">
                        <div className="gallerySlide" onClick={() => {setSlide(file.src);setLightbox(!lightbox)}}>
                            {(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(file.src)?<img src={STORAGEURL+file.src} alt={file.name} />: <div className="video"><ReactPlayer url={STORAGEURL+file.src} /></div>}
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
            {srcs&&<FsLightbox
				toggler={lightbox}
				sources={srcs}
                source={STORAGEURL+slide}
			/>}
        </section>
    )
}

export default GallerySwiper;