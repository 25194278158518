import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards } from 'swiper/modules';
import {Info, FacebookLogo,InstagramLogo,At } from "@phosphor-icons/react";
import 'swiper/css';
import 'swiper/css/effect-cards';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import Modal from './UI/Modal';
import { useState } from 'react';
import parse from 'html-react-parser';
import { STORAGEURL } from '..';

const PhotoSwiper = ({data}) => {
    const [modal, setModal] = useState(null);
    return (
        <>
            <Swiper
                effect={'cards'}
                cardsEffect={{perSlideRotate:8}}
                grabCursor={true}
                initialSlide={1}
                modules={[EffectCards]}
                className="photoSwiper"
            >
                {data?.map(card=><SwiperSlide key={card.id} className='p-4 shadow-swiper'>
                    <div onClick={()=>setModal(card)}>
                        <img src={STORAGEURL+card.photo} className='aspect-square object-cover object-center' alt='Fotka studenta/ky' />
                        <div className='absolute top-8 tablet:top-4 flex flex-row-reverse'>
                            {card.langs.replace(/\s/g, '').split(',').reverse().map((l,i)=><span key={i} className={"fi fi-"+l+" fis text-6xl tablet:text-4xl mobil:text-xl rounded-full"} style={window.innerWidth>414?{left:i+"rem"}:{left:i*0.25+"rem"}}></span>)}
                        </div>
                        <Info className='absolute text-4xl right-4 top-4 mobil:text-xl mobil:top-3' />
                    </div>
                    <div className='py-4 text-center'>
                        <h4 className='CoveredByYourGrace' onClick={()=>setModal(card)}>{card.name}</h4>
                        <div className='pt-2 flex justify-center items-center gap-2 text-base-color text-3xl'>
                            <span className='text-lg tablet:text-base uppercase font-bold pr-2'>Napiš mi!</span>
                            {card.fb&&<a href={card.fb} target='_blank' ><FacebookLogo /></a>}
                            {card.ig&&<a href={card.ig} target='_blank'><InstagramLogo  /></a>}
                            {card.email&&<a href={"mailto:"+card.email} ><At /></a>}
                        </div>
                    </div>
                </SwiperSlide>)}
            </Swiper>
            {modal&&<Modal state={[modal, setModal]} >
                <div className='w-[80vw] max-w-[1100px] aspect-video flex tablet:aspect-auto tablet:flex-col-reverse'>
                    <div className='halffull border-r border-black pr-8 flex flex-col justify-between tablet:border-none tablet:pr-0'>
                        <p className='CoveredByYourGrace text-2xl pb-2 mobil:text-xl parsedDiv'>{parse(modal.message)}</p>
                        <div className='flex items-center gap-2 text-base-color text-3xl'>
                            <span className='text-lg uppercase font-bold pr-2'>Napiš mi!</span>
                            {modal.fb&&<a href={modal.fb} target='_blank' ><FacebookLogo /></a>}
                            {modal.ig&&<a href={modal.ig} target='_blank'><InstagramLogo  /></a>}
                            {modal.email&&<a href={"mailto:"+modal.email} ><At /></a>}
                        </div>
                    </div>
                    <div className='halffull pl-8 CoveredByYourGrace text-2xl flex flex-col items-start dopis tablet:pl-0 tablet:pb-8'>
                        <img src={STORAGEURL+modal.photo} className='w-1/2 self-end tablet:self-start aspect-square object-cover object-center' alt='Fotka studenta/ky' />
                        <p className='mt-auto tablet:pt-8'>{modal.name}</p>
                        <p>Studuju {modal.program}</p>
                        <p>Mluvím {modal.langs.replace(/\s/g, '').split(',').map(l=>jazyky[l]).join(", ")}</p>
                    </div>
                </div>
            </Modal>}
        </>
    )
}

export default PhotoSwiper;



const jazyky = {
    gb: 'anglicky',
    us: 'anglicky',
    fr: 'francouzsky',
    es: 'španělsky',
    ru: 'rusky',
    pt: 'portugalsky',
    de: 'německy',
    dz: 'arabsky',
    eg: 'arabsky',
    sa: 'arabsky',
    jp: 'japonsky',
    kr: 'korejsky',
    tr: 'turecky',
    it: 'italsky',
    vn: 'vietnamsky',
    th: 'thajsky',
    nl: 'nizozemsky',
    se: 'švédsky',
    no: 'norsky',
    dk: 'dánsky',
    fi: 'finsky',
    pl: 'polsky',
    cz: 'česky',
    sk: 'slovensky',
    hu: 'maďarsky',
    ro: 'rumunsky',
    bg: 'bulharsky',
    gr: 'řecky',
    ua: 'ukrajinsky',
    cn: 'čínsky',
    tw: 'čínsky',
};