import { useState } from "react"
import parse from 'html-react-parser';
import { useContext } from "react";
import { AppContext } from "../App";
import { STORAGEURL } from "..";

const Programy = () => {
    const pagedata = useContext(AppContext);
    const {tab, setTab, language, why_text, why_img} = pagedata;
    return (
        <section className="py-top px-2vw">
            {language==='cz'&&<div className="flex tablet:flex-col tablet:gap-4 justify-center">
                <a onClick={()=>setTab('bc')} className={'programtab '+(tab==='bc'?'active':'')}>Bakalářské programy</a>
                <a onClick={()=>setTab('mgr')} className={'programtab '+(tab==='mgr'?'active':'')}>Magisterské programy</a>
            </div>}
            <div className="w-full py-10 flex items-center gap-x-vw gap-y-8 tablet:flex-col">
                <div className="halffull">
                    <h2 className="pb-6">{language==='cz'?(tab==='bc'?'Bakalářské programy':'Magisterské programy'):"Why FIR"}</h2>
                    <div className="parsedDiv ">{language==='cz'?parse(pagedata[tab+'_text']):parse(why_text)}</div>
                </div>
                <div className="halffull">
                    <img src={STORAGEURL+pagedata[language==='cz'?(tab+'_img'):why_img]} className="w-full  max-h-[650px] object-cover" />
                </div>
                <img />
            </div>
        </section>
    )
}

export default Programy