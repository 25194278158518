import parse from 'html-react-parser';
import { useContext } from 'react';
import { STORAGEURL } from '..';
import { AppContext } from '../App';

const Prihlaska = () => {
    const {prihlaska_title, prihlaska_text, prihlaska_link, prihlaska_img} = useContext(AppContext);
    return (
        <section className="bg-base-color flex justify-between tablet:flex-col-reverse gap-vw text-white">
            <img className='halffull max-h-[650px] object-cover' src={STORAGEURL+prihlaska_img} alt='Prihlaska fotka' />
            <div className='w-1/2 pr-vw py-top m-auto tablet:w-[90%]'>
                <h2 className='max-w-72'>{prihlaska_title}</h2>
                {prihlaska_text&&<div className="parsedDiv !py-8">{parse(prihlaska_text)} </div>}
                {prihlaska_link&&<a className='button black' href={prihlaska_link} target='_blank' >Podat přihlášku</a>}
            </div>
        </section>
    )
}
export default Prihlaska;