import parse from 'html-react-parser';
import { useContext } from 'react';
import { AppContext } from '../App';
import { STORAGEURL } from '..';


const Uvod = () => {
    const {h1, svg,perex,intropic, boxes } = useContext(AppContext);
    return (
        <section className="bg-[#F6F6F6]">
            <div id='intro' className="bg-base-color introbg text-white min-h-[90vh] w-full py-10 tablet:pt-24 px-2vw flex flex-col justify-center gap-8">
                <img src={STORAGEURL+intropic} className='absolute right-vw bottom-0 w-1/2 max-h-[90%] object-contain tablet:hidden' />
                <h1 className="text-5xl w-2xl max-w-[50%] leading-tight tablet:text-4xl tablet:max-w-full">{parse(h1)}
                {parse(svg)}
                </h1>
                <div className="w-xl parsedDiv !max-w-[65%] tablet:max-w-full">{parse(perex)}</div>
            </div>
            <div className="px-vw pb-8 grid grid-cols-3 tablet:grid-cols-1 gap-4">
                {boxes?.map(box=>
                        <div className="bg-[#F6F6F6] p-8 rounded-3xl shadow-xl -translate-y-10" key={box.id}>
                            <img src={STORAGEURL+box.icon} alt='Icon' />
                            <h3 className='py-4'>{box.name}</h3>
                            <div className="parsedDiv">{parse(box.text)}</div>
                        </div>
                    )}
            </div>
        </section>
    )
}

export default Uvod