import {useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';

//https://react-select.com/
 
const Dropdown = ({
                      className = '',
                      name,
                      label,
                      required,
                      defaultValue,
                      styles,
                      single,
                      options = [],
                      multiValueStyle,
                      isSearchable,
                      creatable,
                      invisible,
                      ...rest
                  }) => {
    const [value, setValue] = useState();
    const methods = useFormContext();
    const animatedComponents = makeAnimated();

    const validationRules = {
        required: required ? "Toto pole je povinné" : false,
      };

    const onChange = (selectedValues) => {
        setValue(selectedValues)
        if (methods) {
            methods.setValue(name, selectedValues)
        } 
        if (rest.onChange) {
            rest.onChange(selectedValues);
        }

    }
    
    useEffect(()=>{
        if (defaultValue) onChange(defaultValue);
    }, [])

    return (
        <div className={'superdropdown ' + className} style={invisible?{display:'none'}:{}}>
            {label && <p>{label} {required && <sup>*</sup>}</p>}
            {creatable ? <CreatableSelect 
                isMulti 
                options={options}
                {...methods && methods.register(name, validationRules)}
                {...rest}
                value={value}
                defaultValue
                name={name}
                unstyled
                styles={styles}
                components={animatedComponents}
                isSearchable={isSearchable}
                onChange={onChange}
                classNamePrefix="superdropdown"
                menuPosition='fixed'
            /> : <Select
                options={options}
                {...methods && methods.register(name, validationRules)}
                {...rest}
                value={value}
                defaultValue
                name={name}
                unstyled
                styles={styles}
                components={animatedComponents}
                isSearchable={isSearchable}
                onChange={onChange}
                hideSelectedOptions
                classNamePrefix="superdropdown"
                menuPosition='fixed'
            />}
            {methods && methods.formState.errors[name] && !single &&
                <p className='err'>{methods.formState.errors[name]?.message}</p>}
        </div>
    )
}

export default Dropdown;

