import Footer from "./components/Footer";
import Header from "./components/Header/Header"
import Navigation from "./components/Header/Navigation"
import { ReactLenis, useLenis} from '@studio-freight/react-lenis';
import logo from "./assets/svg/logo.svg"
import { useEffect, useState } from "react";
import {initCookieConsent} from "./components/UI/Cookies";
import { ToastProvider } from "./components/UI/useToast";
import HashScroll from "./components/UI/HashScroll";
import Dropdown from "./components/Form/Dropdown";
import Uvod from "./components/Uvod";
import Programy from "./components/Programy";
import ZeptejSe from "./components/ZeptejSe";
import Tajemnice from "./components/Tajemnice";
import Grid from "./components/Grid";
import Prihlaska from "./components/Prihlaska";
import { createContext } from "react";
import axios from 'axios';
import { APIURL } from ".";
import { useNavigate, useParams } from "react-router-dom";
import GallerySwiper from "./components/GallerySwiper";
import EnVideo from "./components/EnVideo";

export const AppContext = createContext();

const App = () => {
  const {translation} = useParams();
  const [data, setData] = useState();
  const [header, setHeader] = useState('');
  const lenis = useLenis();
  const navigate = useNavigate();
  const lang = process.env.REACT_APP_LANGUAGE;
  // const scroll = (hash) => {
  //   lenis.scrollTo(document.getElementById(hash));
  // }

  const [tab,setTab] = useState('bc');

  const langArray = [
    {value:'en', label:'EN'},
    {value:'de', label:'DE'},
    {value:'es', label:'ES'}
  ]

  useEffect(()=>{
    if (process.env.NODE_ENV === 'production') {
      initCookieConsent();
    }
  })

  const getPageData = async () => {
    let preklad = translation?translation:lang==='cz'?'cs':'en';
    await axios.post(APIURL+'/page', {name: lang==='cz'?'hp':'hp_en', language: preklad}).then(async response =>{
      await axios.post(APIURL+'/collection/items', {type: 'boxes_'+lang, orderAsc: 'ASC', limit: 100, page: 1,language: preklad}).then(resp =>{
        setData({...response.data.body, boxes: resp.data.body.items, language:lang, translation: preklad});
      })
    }).catch(()=>{
      navigate('/')
    })
  }

  useEffect(()=>{
    lenis?.on('scroll', (e) => {
      if (e.actualScroll > (document.getElementById('intro')?.scrollHeight*0.75)) {
        setHeader('invert')
      } else {
        setHeader('')
      }
    })
}, [lenis])

  useEffect(()=>{
    getPageData();
  }, [lang, translation])

  if (data) return (
    <ReactLenis root>
      <ToastProvider>
        <AppContext.Provider value={{...data, tab, setTab}}>
          <Header fixed className={header} >
            <img id='logo' src={logo} alt="FNV logo" />
            <Navigation gap='2rem' moveRight>
              <a href={data.header_url} target="_blank" className="button black">{window.innerWidth>414?"Podat přihlášku":"Přihláška"}</a>
              {lang==='en'&&<Dropdown name='lang' options={langArray} defaultValue={langArray.find(l=>l.value===translation?translation:'en')} onChange={({value})=>navigate(value==='en'?'/':('/'+value))} />}
            </Navigation>
          </Header>
          
          <main>
            <Uvod />
            <Programy />
            <ZeptejSe />
            <Tajemnice />
            {lang==='cz'&&<GallerySwiper />}
            <div className="gradienttprosim">
              {lang==='cz'?<Grid />:<EnVideo />}
            </div>
            <Prihlaska />
          </main>
          <Footer address={data.address}/>
          <HashScroll />
        </AppContext.Provider>
      </ToastProvider>
    </ReactLenis>
  );
}

export default App;
